<template>
  <v-container fluid>
    <div>
      <v-row>
        <v-col>
          <v-toolbar flat color="transparent">
            <v-toolbar-title>
              {{ $t("admin.salarybasis.title") }}
            </v-toolbar-title>
          </v-toolbar>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="d-flex align-start" style="gap: 1rem">
          <v-card>
            <v-card-text>
              <v-date-picker
                v-model="dates"
                :first-day-of-week="1"
                :max="maxDate"
                :readonly="loading"
                @change="handleRangeChange"
                range
              />

              <v-switch
                v-model="hideMissing"
                class="ma-2"
                label='Skjul "Mangler i TPA portalen"'
              />
            </v-card-text>
            <v-card-text>
              Hent månedlig oversigt for alle i den valgte periode.
              <v-btn
                value="downloadoverview"
                :loading="downloads.includes('downloadoverview')"
                style="margin-top: 8px"
                tile
                block
                @click="handleDownloadOverview"
              >
                <v-icon left> mdi-download </v-icon>
                Hent oversigt
              </v-btn>
            </v-card-text>
          </v-card>

          <v-data-iterator
            :loading="loading"
            :items="filtered"
            :items-per-page="-1"
            item-key="id"
            no-data-text="Der blev ikke fundet registreringer i den valgte periode."
            hide-default-footer
            disable-filtering
          >
            <template v-slot:default="{ items }">
              <v-row>
                <v-col v-for="item in items" :key="item.name" cols="12">
                  <v-card :flat="!item.existsInTPA">
                    <v-card-title>
                      <h4>{{ item.name }} (Avaza login: {{ item.email }})</h4>
                    </v-card-title>
                    <v-card-subtitle>
                      <v-chip
                        v-if="!item.existsInTPA"
                        color="red"
                        text-color="white"
                      >
                        Mangler i TPA portalen
                      </v-chip>
                      <div v-if="item.existsInTPA">
                        {{ item.termOfEmployment }}
                      </div>
                    </v-card-subtitle>
                    <v-card-text>
                      <div>
                        Antal registreringer: {{ item.numberOfEntries }}
                      </div>
                      <div>
                        Anslået total timetal:
                        {{ item.sumOfDuration.toFixed(2) }}
                      </div>
                    </v-card-text>
                    <v-card-actions>
                      <v-btn
                        tile
                        :value="item.id"
                        @click="handleDownload"
                        :loading="downloads.includes(item.id)"
                        :disabled="!item.existsInTPA"
                      >
                        <v-icon left> mdi-download </v-icon>
                        Hent løngrundlag
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>
            </template>
          </v-data-iterator>
        </v-col>
        <v-col xl="6" lg sm="12">
          <v-row v-if="count > 0">
            <v-col>
              Visning:
              {{ count }}
              <span v-if="hideMissing">(af {{ totalCount }})</span>
              personer.
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import fileHandler from "@/mixins/fileHandler";
import Loading from "@/components/Loading.vue";
import moment from "moment";

export default {
  mixins: [fileHandler],
  components: { Loading },
  data() {
    return {
      model: {
        metadata: {},
        query: {},
      },
      loading: false,
      dates: [],
      downloads: [],
      hideMissing: false,
    };
  },
  async mounted() {
    try {
      this.loading = true;

      const response = await this.$AuthService.api.get(
        "basiscalculator/GetPersonSummaries"
      );

      this.model = response.data;

      this.dates = [
        this.moment(response.data.query.periodFrom).format("YYYY-MM-DD"),
        this.moment(response.data.query.periodTo).format("YYYY-MM-DD"),
      ];
    } catch (error) {
      console.error(error);
      this.$notifier.showError({ message: "Kunne ikke hente personer" });
    } finally {
      this.loading = false;
    }
  },
  computed: {
    count() {
      return this.filtered.length;
    },
    totalCount() {
      return this.model.metadata.personsSummaries?.length;
    },
    filtered() {
      return !this.hideMissing
        ? this.model.metadata.personsSummaries
        : this.model.metadata.personsSummaries?.filter((x) => x.existsInTPA);
    },
    maxDate() {
      return moment(new Date(Date.now()).toISOString()).format("YYYY-MM-DD");
    },
  },
  methods: {
    handleRangeChange(dates) {
      dates = dates.sort();
      this.loading = true;
      this.model.metadata.personsSummaries = [];
      this.$AuthService.api
        .get(
          `basiscalculator/GetPersonSummaries?fromDate=${dates[0]}&toDate=${dates[1]}`
        )
        .then((result) => {
          this.model = result.data;
        })
        .catch((error) => {
          console.error(error);
          this.$notifier.showError({ message: "Kunne ikke hente personer." });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleDownload({ currentTarget: { value } }) {
      var employee = this.model.metadata.personsSummaries?.find(
        (e) => e.id === value
      );

      if (employee) {
        this.downloads.push(value);

        var parameters = new URLSearchParams({
          parserType: "SalaryBasis",
          fromDate: this.dates[0],
          toDate: this.dates[1],
          employeeEmail: employee.email,
        }).toString();

        this.streamFile(`basiscalculator/GenerateBasis?${parameters}`)
          .then(() => {
            this.removeIndex(value);
          })
          .catch(() => {
            this.removeIndex(value);
            this.$notifier.showError({
              message: `Kunne ikke hente løngrundlag for ${employee.name}.`,
            });
          });
      }
    },
    handleDownloadOverview({ currentTarget: { value } }) {
      this.downloads.push(value);
      var parameters = new URLSearchParams({
        fromDate: this.dates[0],
        toDate: this.dates[1],
      }).toString();

      this.$notifier.showMessage({
        message: `Henter måneds oversigt for perioden ${this.dates[0]} - ${this.dates[1]}. Det kan tage et par minutter før den downloades.`,
      });

      this.streamFile(`BasisCalculator/GenerateMonthlyOverview?${parameters}`)
        .catch(() => {
          this.$notifier.showError({
            message: `Kunne ikke hente måneds oversigt for perioden ${this.dates[0]} - ${this.dates[1]}.`,
          });
        })
        .finally(() => {
          this.removeIndex(value);
        });
    },
    removeIndex(id) {
      var removeIndex = this.downloads.indexOf(id);
      this.downloads.splice(removeIndex, 1);
    },
  },
};
</script>

<style>
</style>