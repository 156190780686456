const fileHandler = {
  methods: {
    async streamFile(url, response = null) {
      if (!response) {
        response = await this.$AuthService.api.get(url, { responseType: 'arraybuffer' });
      }

      const disposition = response.headers['content-disposition'];
      let filename = '';

      if (disposition?.includes('attachment')) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(disposition);

        if (matches !== null && matches[1]) {
          filename = matches[1].replace(/['"]/g, '');
        }
      }

      const blob = new Blob([response.data], { type: response.headers['content-type'] });

      if (window.navigator?.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, filename);
      } else {
        const link = document.createElement('a');
        link.download = filename;
        link.href = window.URL.createObjectURL(blob);
        link.click();
      }
    }
  }
}

export default fileHandler;