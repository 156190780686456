<template>
  <div v-if="!!loading" style="position: fixed; top: 200px; left: 0; width: 100%;">
    <v-row
      class="fill-height"
      align-content="center"
      justify="center"
    >
      <v-col cols="12" class="text-center text-subtitle-1 text--secondary">
        {{ text }}
      </v-col>

      <v-col cols="1">
        <v-progress-linear
          :height="6"
          color="primary"
          indeterminate
          rounded
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'Loading',
  props: {
    loading: {
      type: Boolean,
      required: true
    },
    text: {
      type: String,
      default: 'Indlæser'
    }
  }
}
</script>

<style>
#loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 400px;
  height: 100%;
}
</style>